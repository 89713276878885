@font-face {
  font-family: 'Latin Modern Mono';
  src: url('./assets/fonts/immono-regular.woff') format('woff');
  font-weight: 400;
}

body {
  background-color: #FAFAFA;
  margin: 0 auto;
  padding: 0;

  font-family: 'Nunito', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, p, div, li, label {
  color: #022D4F;
}

a {
  color: #4282F3;
}


b {
  font-weight: 600;
}

.container-card__content {
  margin: 0 auto;
}

.container-card__subheading {
  padding: 0 24px;
}

/* Datasheet styling start */
.data-grid {
  width: 100%;
}

.value-viewer {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  margin-left: 8px;
}

.data-grid .value-viewer,
.data-grid .data-editor {
  height: 40px !important;
  display: flex !important;
  align-items: center;
}

.data-grid .cell .data-editor {
  width: 100% !important;
  text-align: left !important;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
/* Datasheet styling end */

.simple-form {
  width: 100%;
}

.edit-page div:first-child {
  margin-top: 0;
}

.list-page {
  width: 100% !important;
}

.vfStar, .vfTick {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: inherit;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}
