.highlight-share-popover {
	max-width: 8em;
	pointer-events: none;
	-webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, .35));
	filter: drop-shadow(0 1px 3px rgba(0, 0, 0, .35));
	-webkit-animation: a .3s;
	animation: a .3s;
	z-index: 999;
}

.highlight-share-popover:before {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	width: 0;
	height: 0;
	margin: .25em -.5em;
	border-width: .5em .5em 0;
	border-style: solid;
	border-color: #0099bc transparent
}

.highlight-share-popover > ul {
	pointer-events: auto;
	list-style: none;
	padding: 0;
	margin: -.75em 0 0;
	white-space: nowrap;
	background: #0099bc;
	color: #fff;
	border-radius: .25em;
	position: absolute;
	left: 50%;
	-webkit-transform: translate(-50%, -100%);
	-ms-transform: translate(-50%, -100%);
	transform: translate(-50%, -100%)
}

.highlight-share-popover > ul > li {
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	text-align: center
}

.highlight-share-popover > ul > li > a {
	display: inline-block;
	width: 100%;
	height: 100%;
	color: inherit;
	box-sizing: border-box;
	padding: .35em
}

.highlight-share-popover > ul > li > a:focus, .highlight-share-popover > ul > li > a:hover {
	background: hsla(0, 0%, 100%, .25)
}

.highlight-share-popover > ul > li > a polyline {
	stroke: #fff
}

@media (pointer: coarse) {
	.highlight-share-popover {
		font-size: 150%
	}

	.highlight-share-popover:before {
		bottom: auto;
		top: 100%;
		border-width: 0 .5em .5em;
		margin-top: 0
	}

	.highlight-share-popover > ul {
		top: 100%;
		transform: translateX(-50%);
		margin: .5em 0 0
	}
}

@media (max-width: 575px) {
	.highlight-share-popover {
		left: 0 !important;
		right: 0 !important;
		width: auto !important;
		max-width: none
	}

	.highlight-share-popover:before {
		bottom: auto;
		top: 100%;
		border-width: 0 .5em .5em;
		margin-top: 0
	}

	.highlight-share-popover > ul {
		top: 100%;
		transform: translateX(-50%);
		margin: .5em 0 0;
		left: 0;
		width: 100%;
		transform: none;
		border-radius: 0;
		text-align: center
	}
}

@-webkit-keyframes a {
	0% {
		-webkit-transform: translateY(-3em);
		opacity: 0
	}
	80% {
		-webkit-transform: translateY(.5em);
		opacity: 1
	}
	to {
		-webkit-transform: translateY(0)
	}
}

@keyframes a {
	0% {
		transform: translateY(-3em);
		opacity: 0
	}
	80% {
		transform: translateY(.5em);
		opacity: 1
	}
	to {
		transform: translateY(0)
	}
}
