.formio-form .title {
    font-size: 20px;
    paddingtop: '24px';
    paddingbottom: 0;
    color: #4BC1CA;
}
.formio-form .content {
    paddingbottom: 0;
}
.formio-form .month {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: -10px;
}

.formio-component-button{
    float: right;
}
.formio-form .btn-primary {
    display: block;
    width: 100%;
    height: 38px;
    background-color: #4282F3;
    border-color: #4282F3;
    font-size: 14px;
    font-size: 14px;
    border-radius: 2rem;
    font-weight: 700;
    box-shadow: none !important;
}
.formio-form .btn-primay:hover {
    background-color: #C1B8B3;
    border-color: #C1B8B3;
}
.formio-form .tooltip-inner {
    border: 1px solid #4BC1CA;
    background-color: #fff;
    color: #414141;
    text-align: left;
}
.formio-form .fa-question-circle:before {
    color: #4BC1CA;
}
.row.formio-component-columns {
    margin-bottom: -15px;
}

.formio-form input[type='text'] {
    height: 40px;
    width: 60px;
    margin: auto;
    text-align: center;
}
.downgrade .formio-form input[type='text'] {
    width: 100%;
    text-align: left;
}


.alert.alert-success{
    display: none;
}
